import iconHomeVue from "@/components/icon/icon-home.vue";
import iconClockVue from "@/components/icon/icon-clock.vue";
import iconMapPinVue from "@/components/icon/icon-map-pin.vue";
import iconListCheckVue from "@/components/icon/icon-list-check.vue";
import IconFlag from "@/components/ui/icon/icon-flag.vue";

export interface HistoryItemProps {
  id: number;
  value: string;
  label: string;
  icon: Function;
  metadata: Record<string, string>;
  completed: boolean;
  description?: string;
  status?: "ended" | "repproved" | "approved" | "in_progress";
}

export interface LinkedTaskProps {
  id: number;
  title: string;
  responsible: string;
  deadline: string;
  status: "repproved" | "approved" | "in_progress";
  details: string;
  metadata: {
    start_date: string;
    end_date: string;
    completed: boolean;
  };
}

export interface TableItemProps {
  Id: number;
  title: string;
  area: string;
  initial_risks: number;
  state: string;
  user_responsible: string;
  description: string;
  created_at: string;
}

// Função para formatar dados da API
export function formatData(payload: any) {
  return payload.map((item) => {
    const residualRisk =
      item.risk_treatment_activities?.length > 0
        ? item?.risk_treatment_activities.map(
            (residual) => residual?.risk?.risk_monitoring?.residual_risk
          )
        : null;

    const initialRisk =
      item?.risk_treatment_activities.map(
        (initial) => initial?.risk?.initial_risk
      ) || "";

    let riskLevel = "";
    const allRisks = `${residualRisk} ${initialRisk}`.toLowerCase();

    if (allRisks.includes("alto")) {
      riskLevel = "Alto";
    } else if (allRisks.includes("médio")) {
      riskLevel = "Médio";
    } else if (allRisks.includes("baixo")) {
      riskLevel = "Baixo";
    }
    // Formatar a data de criação no formato DD/MM/YYYY
    const formattedDate = item?.created_at
      ? (() => {
          const date = new Date(item.created_at);
          const day = String(date.getDate()).padStart(2, "0"); // Adiciona zero à esquerda se necessário
          const month = String(date.getMonth() + 1).padStart(2, "0"); // Os meses começam do zero, então adicionamos 1
          const year = date.getFullYear();
          return `${day}/${month}/${year}`; // Formato DD/MM/YYYY
        })()
      : null;
    const userResponsible = item?.user_responsible?.displayName || "";

    return {
      ...item,
      Id: item?.id,
      id_temporary: item?.id_temporary,
      title: item?.name_activity,
      area: item?.area_modules?.area?.description,
      description: item?.description,
      initial_risk: riskLevel,
      state: item?.status,
      user_responsible: userResponsible,
      id_user_responsible: item?.owner?.id,
      created_at: formattedDate,
    };
  });
}
